<template>
  <div class="hospedaje-comp">
    <div class="title-holder d-flex align-items-center justify-content-center p-3">
      <h1 class="title">Toda la Información</h1>
    </div>

    <div class="row">
      <div class="col-12 col-xl-6">
        <h1 class="event-title">Dress code</h1>
        <div class="event">
          <h1 class="event-title-bold">Formal</h1>
          <img alt="Banner" src="../../assets/icons/icon-formal.svg" />
          <h1 class="event-sub-title-bold mt-xl-5">Importante:</h1>
          <div class="row mt-3 d-flex align-items-center">
            <div class="col-6">
              <img alt="Banner" class="mt-xl-5" src="../../assets/icons/icon-shoe.svg" />
              <h3 class="event-sub-data">Llevar zapatos cómodos.</h3>
            </div>
            <div class="col-6">
              <img alt="Banner" src="../../assets/icons/icon-coat.svg" />
              <h3 class="event-sub-data">Llevar abrigo.</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <h1 class="event-title mt-4 mt-xl-0">Hospedaje</h1>
        <div class="event">
          <h1 class="event-title-bold">City Express Plus Ensenada</h1>
          <img alt="Banner" src="../../assets/icons/icon-hotel.svg" />
          <h1 class="event-sub-title-bold mt-4 mb-3">Información:</h1>
          <div class="row">
            <div class="col-6 mt-2">
              <img alt="Banner" src="../../assets/icons/icon-code.svg" />
              <h3 class="event-sub-data"><b>Código:</b><br />(BODAMAYAN23)</h3>
            </div>
            <div class="col-6">
              <img alt="Banner" src="../../assets/icons/icon-phone.svg" />
              <h3 class="event-sub-data"><b>Teléfono:</b><br />+646 152 8450</h3>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center mt-1">
            <img alt="Banner" src="../../assets/icons/icon-bnb.svg" />
            <h3 class="event-sub-data">
              <b>Airb&b:</b><br />El sauzal – Ensenada, <br />
              Baja California
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-6">
        <h1 class="event-title mt-4">Transporte</h1>
        <p class="event-description--green">
          Se recomienda tener transporte de ida y vuelta reservado previamente, en especial para la
          salida.
        </p>
        <div class="event mt-3">
          <h1 class="event-title-bold">Villarino Tours</h1>
          <img alt="Banner" src="../../assets/icons/icon-van.svg" />
          <h1 class="event-sub-title-bold">Información:</h1>
          <div class="row">
            <div class="col-6 mt-2">
              <img alt="Banner" src="../../assets/icons/icon-code.svg" />
              <h3 class="event-sub-data"><b>Teléfono:</b><br />+52 664 437 9035</h3>
            </div>
            <div class="col-6">
              <img alt="Banner" src="../../assets/icons/icon-phone.svg" />
              <h3 class="event-sub-data">
                <b>Código:</b><br />
                (AndreayManuel)
              </h3>
            </div>
          </div>
          <h3 class="event-description mt-3 mb-3">
            ¡Mejor juntos que separados! Para facilitar la logística y comodidad de nuestros
            invitados, hemos pactado una tarifa preferencial con “Villarino Tours” para trasladar
            Ida y regreso a todos nuestros invitados del punto de partida hasta el venue donde se
            llevara a cabo la boda.
          </h3>
          <button class="btn-reserve" @click="goToVillarino()">
            Reservar <br />
            Transporte
          </button>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <h1 class="event-title mt-4">Transporte</h1>
        <p class="event-description--green">
          Se recomienda tener transporte de ida y vuelta reservado previamente, en especial para la
          salida.
        </p>
        <div class="event">
          <h1 class="event-title-bold">Transportes Joe</h1>
          <img alt="Banner" src="../../assets/icons/icon-van.svg" />
          <h1 class="event-sub-title-bold mt-3">Información:</h1>
          <div class="d-flex flex-column justify-content-center align-items-center mt-3">
            <img alt="Banner" src="../../assets/icons/icon-phone.svg" />
            <h3 class="event-sub-data mt-2">
              <b>Teléfono:</b><br />
              +52 646 136 5446
            </h3>
          </div>
        </div>
      </div>
    </div>

    <h1 class="event-title mt-4">Regalo</h1>
    <div class="event">
      <h1 class="event-title-bold mb-4">¡Que nos puedas acompañar!</h1>
      <img alt="Banner" src="../../assets/icons/icon-gift.svg" />
      <h3 class="event-description mt-4 mb-3">
        Tu presencia el día de la boda es lo más importante para nosotros, aun así, si quieres
        contribuir a nuestras aventuras y futura vida juntos, te facilitamos nuestro número de
        cuenta y la liga a nuestra mesa de regalos.
      </h3>
      <h2 class="event-sub-title-bold">Manuel Turrent Hegewisch</h2>
      <div class="d-flex flex-column justify-content-center align-items-center mt-4">
        <img alt="Banner" src="../../assets/icons/icon-cash.svg" />
        <h2 class="event-sub-title-bold mt-2">BBVA Número de cuenta:</h2>
        <div class="d-flex justify-content-center align-items-center mb-4">
          <h3 class="event-description m-0">288 150 5823</h3>
          <div>
            <img
              alt="Banner"
              src="../../assets/icons/icon-copy.svg"
              class="ms-1 me-1 ms-xl-3 me-xl-3"
              @click="copy('288 150 5823')"
            />
            <span class="text-light label-copy">Copiar</span>
          </div>
        </div>
        <img alt="Banner" src="../../assets/icons/icon-key.svg" />
        <h2 class="event-sub-title-bold">CLABE:</h2>
        <div class="d-flex justify-content-center align-items-center mb-4">
          <h3 class="event-description m-0">012 180 028815058234</h3>
          <div>
            <img
              alt="Banner"
              src="../../assets/icons/icon-copy.svg"
              class="ms-1 me-1 ms-xl-3 me-xl-3"
            />
            <span class="text-light label-copy" @click="copy('012 180 028815058234')">Copiar</span>
          </div>
        </div>
        <img alt="Banner" src="../../assets/icons/icon-gift-table.svg" />
        <h2 class="event-sub-title-bold">Liga mesa de regalos:</h2>
        <a
          href="https://www.zepika.com/giftr/registry/view/uid/andreaymanuel/"
          class="event-description text-center"
          target="_blank"
          >https://www.zepika.com/giftr/<br />registry/view/uid/andreaymanuel/</a
        >
      </div>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  methods: {
    copy(text) {
      window.prompt('Copiar: Ctrl+C, Enter', text);
    },
    goToVillarino() {
      this.$router.push('/villarino');
    },
  },
};
</script>

<style lang="scss" scoped>
.hospedaje-comp {
  .title-holder {
    width: 100%;
    height: 127px;
    left: 0px;
    top: 74px;

    .title {
      font-family: "Hammersmith One";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      text-align: center;

      color: #8bc89d;
    }
  }
  .event {
    background: #8bc89d;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 15px;
    margin: 0 16px;

    &-pic {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 0;
    }

    &-title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      text-align: center;

      color: #8bc89d;
    }

    &-title-bold {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      text-align: center;

      color: #ffffff;
    }
    &-sub-title-bold {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;

      color: #ffffff;
    }

    &-description {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: justify;

      color: #ffffff;
    }
    &-description--green {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      margin-left: 5px;
      margin-right: 5px;

      color: #8bc89d;
    }
    &-sub-title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;

      color: #ffffff;
    }
    &-sub-data {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;

      color: #ffffff;
    }
    &-sub-cash {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;

      color: #ffffff;
    }
    .btn-reserve {
      background: #9cd9b0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      border-radius: 5px;
      border: 0;
      width: 163px;
      height: 48px;
      color: white;
    }
  }
}
@media (min-width: 1200px) {
  .hospedaje-comp {
    .title-holder {
      width: 100%;
      height: 181px;
      left: 0px;
      top: 74px;
      margin-bottom: 39px;

      .title {
        font-family: "Hammersmith One";
        font-style: normal;
        font-weight: 400;
        font-size: 70px;
        line-height: 88px;
        text-align: center;

        color: #8bc89d;
      }
    }
    .event {
      background: #8bc89d;
      border-radius: 10px;
      margin-bottom: 25px;
      padding: 30px;
      margin: 0 30px;
      min-height: 666px;

      img {
        transform: scale(1.5);
        margin: 20px;
      }

      &-pic {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 0;
      }

      &-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 61px;
        text-align: center;

        color: #8bc89d;
      }

      &-title-bold {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        text-align: center;

        color: #ffffff;
      }
      &-sub-title-bold {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 49px;
        text-align: center;

        color: #ffffff;
      }

      &-description {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        text-align: justify;

        color: #ffffff;
      }
      &-description--green {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;

        color: #8bc89d;
      }
      &-sub-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        text-align: center;

        color: #ffffff;
      }
      &-sub-data {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        text-align: center;

        color: #ffffff;
      }
      &-sub-cash {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;

        color: #ffffff;
      }
      .btn-reserve {
        background: #9cd9b0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 33px;
        text-align: center;
        border-radius: 5px;
        border: 0;
        width: 290px;
        height: 84px;
        color: white;
        margin-top: 25px;
      }
    }
    .label-copy {
      font-size: 20px;
    }
  }
}
</style>
