<template>
  <div id="app">
    <div id="nav-holder">
      <div id="nav-mobile" class="d-flex align-items-center justify-content-between w-100">
        <h1 class="couple-title">Andrea <span class="couple-title-ampersand">&</span> Manuel</h1>
        <img
          alt="Banner"
          src="@/assets/icons/icon-burger-green.svg"
          @click="menuIsShown = !menuIsShown"
          v-if="!menuIsShown"
        />
      </div>
      <div id="nav-desktop" class="d-flex align-items-center justify-content-between w-100">
        <h1 class="couple-title">Andrea <span class="couple-title-ampersand">&</span> Manuel</h1>
        <div class="links" @click="menuIsShown = false">
          <router-link to="/">Home</router-link>
          <a href="#quieres-acompanarnos">Confirma Asistencia</a>
          <router-link to="/agenda">Agenda del Evento</router-link>
          <router-link to="/informacion">Toda la Información</router-link>
        </div>
      </div>
    </div>
    <div class="menu-mobile" v-if="menuIsShown">
      <img alt="Banner" src="@/assets/icons/icon-close.svg" @click="menuIsShown = !menuIsShown" />
      <div class="links" @click="menuIsShown = false">
        <router-link to="/">Home</router-link>
        <a href="#quieres-acompanarnos">Confirma Asistencia</a>
        <router-link to="/agenda">Agenda del Evento</router-link>
        <router-link to="/informacion">Toda la Información</router-link>
      </div>
    </div>
    <div class="cond">
      <router-view />
    </div>
    <FooterComp />
  </div>
</template>

<script>
import FooterComp from '@/components/UI/Footer.vue';

export default {
  data() {
    return {
      menuIsShown: false,
    };
  },
  components: {
    FooterComp,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Hammersmith One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav-holder {
  position: fixed;
  top: 0;
  width: 100vw;
  opacity: 0.8;
  background: white;
  box-shadow: 1px 3px 2px -1px rgba(87, 87, 87, 0.54);
  -webkit-box-shadow: 1px 3px 2px -1px rgba(87, 87, 87, 0.54);
  -moz-box-shadow: 1px 3px 2px -1px rgba(87, 87, 87, 0.54);
  z-index: 3;
}

#nav-mobile {
  padding: 30px;
  height: 74px;
  z-index: 2;
  img {
    cursor: pointer;
  }

  .couple-title {
    font-family: "Hammersmith One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;

    color: #8bc89d;
  }

  .couple-title-ampersand {
    font-family: "Hammersmith One";
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 46px;
    text-align: center;

    color: #f5f5f5;
  }
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#nav-desktop {
  display: none !important;
}

.menu-mobile {
  min-width: 50vw;
  min-height: 100vh;
  width: 50vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #8bc89d;
  z-index: 4;
  opacity: 0.85;
  padding: 24px 17px;

  img {
    margin-bottom: 30px;
    position: absolute;
    right: 35px;
    cursor: pointer;
  }

  .links {
    margin-top: 74px;
  }

  a {
    display: block;
    text-decoration: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 40px;
    color: #ffffff;

    &:hover {
      font-weight: 800;
      color: white !important;
    }
  }

  .router-link-exact-active {
    font-weight: 800;
  }
}
.cond {
  margin-top: 74px;
}
@media (min-width: 1200px) {
  #nav-mobile {
    display: none !important;
  }
  #nav-desktop {
    display: flex !important;
    padding: 50px;
    height: 143px;
    z-index: 2;
    img {
      cursor: pointer;
    }

    .couple-title {
      font-family: "Hammersmith One";
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 56px;
      text-align: center;

      color: #8bc89d;
    }

    .couple-title-ampersand {
      font-family: "Hammersmith One";
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 100px;
      text-align: center;

      color: #f5f5f5;
    }
    a {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-right: 20px;
      text-decoration: none;
      color: #8bc89d;

      &.router-link-exact-active {
        color: #42b983;
        font-weight: 800;
      }
    }
  }
  .cond {
    margin-top: 143px;
  }
}
</style>
