<template>
  <div class="reservar">
    <InformacionComp />
    <QuieresAcompanarnosComp />
  </div>
</template>

<script>
// @ is an alias to /src
import InformacionComp from '@/components/Informacion/InformacionComp.vue';
import QuieresAcompanarnosComp from '@/components/Home/QuieresAcompanarnosComp.vue';

export default {
  name: 'Home',
  components: {
    InformacionComp,
    QuieresAcompanarnosComp,
  },
};
</script>
