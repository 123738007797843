<template>
  <div class="cuando-donde-comp">
    <h1 class="title">¿Cuándo y dónde?</h1>
    <div class="countdown">
      <h2 class="countdown-begins mb-5">Inicia en:</h2>
      <!-- <div class="d-flex justify-content-between">
        <div class="d-flex flex-column align-items-center">
          <div class="countdown-time-holder">
            <span id="days">77</span>
          </div>
          <span class="countdown-time-label">Días</span>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="countdown-time-holder">
            <span id="hours">12</span>
          </div>
          <span class="countdown-time-label">Horas</span>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="countdown-time-holder">
            <span id="minutes">40</span>
          </div>
          <span class="countdown-time-label">Minutos</span>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="countdown-time-holder">
            <span id="seconds">50</span>
          </div>
          <span class="countdown-time-label">Segundos</span>
        </div>
      </div> -->
      <div class="countdown-wrapper">
        <Countdown end="May 20, 2023"></Countdown>
      </div>
      <h2 class="countdown-date mt-5 mb-5">Sábado 20 de mayo del 2023</h2>
      <div class="d-flex justify-content-between mt-4">
        <div>
          <h2 class="countdown-place">Lugar</h2>
          <img alt="Banner" src="../../assets/icons/icon-lugar.svg" />
        </div>
        <div>
          <h2 class="countdown-time">Hora</h2>
          <div class="d-flex">
            <div class="countdown-time-holder me-2">
              <span>12</span>
            </div>
            <div class="countdown-time-holder">
              <span>30</span>
            </div>
          </div>
          <h3 class="countdown-time-label--big">Horas</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from 'vuejs-countdown';

export default {
  components: { Countdown },
  mounted() {
    setTimeout(() => {
      const timeTexts = document.querySelectorAll('.vuejs-countdown .text');
      timeTexts[0].innerText = 'Días';
      timeTexts[1].innerText = 'Horas';
      timeTexts[2].innerText = 'Minutos';
      timeTexts[3].innerText = 'Segundos';
    }, 2000);
  },
};
</script>

<style lang="scss">
.cuando-donde-comp {
  .title {
    font-family: "Hammersmith One";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #8bc89d;
  }
  .countdown {
    background: #8bc89d;
    border-radius: 10px;
    padding: 16px;
    margin: 0 5px;

    &-begins {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
    }

    &-time-holder {
      width: 37px;
      height: 37px;
      background: #c3e5cc;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;

        color: #ffffff;
      }
    }

    &-time-label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      margin-top: 10px;
    }
    &-time-label--big {
      margin-top: 5px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
    &-date {
      margin-top: 30px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
    }
    &-place {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;

      color: #ffffff;
    }
    &-time {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;

      color: #ffffff;
    }
  }
  .vuejs-countdown {
    display: flex;

    justify-content: space-between;

    li {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    li::after {
      content: "" !important;
    }
    .digit {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      width: 50px;
      height: 50px;
      background: #c3e5cc;
      border-radius: 5px;
    }
    .text {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      text-transform: capitalize;
      margin-top: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .cuando-donde-comp {
    .title {
      font-family: "Hammersmith One";
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 88px;
      text-align: center;
      color: #8bc89d;
    }
    .countdown {
      background: #8bc89d;
      border-radius: 10px;
      padding: 30px;
      margin: 0 30px;

      &-begins {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        text-align: center;
        color: #ffffff;
      }

      &-time-holder {
        width: 135px;
        height: 135px;
        background: #c3e5cc;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 55px;
          line-height: 67px;
          text-align: center;

          color: #ffffff;
        }
      }

      &-time-label {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;
      }
      &-time-label--big {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 67px;
        text-align: center;
        color: #ffffff;
      }
      &-date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 65px;
        line-height: 79px;
        text-align: center;
        color: #ffffff;
      }
      &-place {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        text-align: center;

        color: #ffffff;
      }
      &-time {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 79px;
        text-align: center;

        color: #ffffff;
      }
      img {
        width: 470px;
        height: auto;
      }
    }
    .countdown-wrapper {
      display: flex;
      justify-content: center;
    }
    .vuejs-countdown {
      display: flex;

      justify-content: space-between;

      li {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      li::after {
        content: "" !important;
      }
      .digit {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 55px;
        line-height: 30px;
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        width: 127.37px;
        height: 127.37px;
        background: #c3e5cc;
        border-radius: 5px;
      }
      .text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 18px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}
</style>
