<template>
  <div class="quieres-acompanarnos-comp mt-3" id="quieres-acompanarnos">
    <h1 class="title">¿Quieres <br />acompañarnos?</h1>
    <h2 class="legend-main m-2">
      Para confirmar tu asistencia, por favor regístrate en el siguiente formulario.
    </h2>
    <h2 class="legend-sub m-2 mb-4">
      No olvides que debes tener el número de tu vuelo y tu hospedaje confirmados antes de llenar el
      formulario.
    </h2>
    <form action="">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="row form-mobile">
          <div class="col-12 col-xl-6">
            <input type="text" placeholder="Nombre" v-model="name" />
          </div>
          <div class="col-12 col-xl-6">
            <input type="mail" placeholder="Email" v-model="mail" />
          </div>
          <div class="col-12 col-xl-6">
            <input type="text" placeholder="Número de vuelo" v-model="flight_number" />
          </div>
          <div class="col-12 col-xl-6">
            <input type="text" placeholder="Hospedaje" v-model="accommodation" />
          </div>
          <div class="col-12 col-xl-12 d-flex flex-column align-items-center">
            <label for="" class="legend-sub m-2 mb-4"
              >¿Tienes alguna alergia / restricción alimenticia?</label
            >
            <textarea
              type="text"
              placeholder="Menciona a que alimento eres alérgico/a, o tu restricción alimenticia"
              v-model="food_allergies"
            />
          </div>
        </div>
        <button @click.prevent="submitFormRSVP">Confirmar asistencia</button>
        <div
          class="alert mt-3"
          :class="{
            'alert-success': requestData.status === 'success',
            'alert-warning': requestData.status === 'error' || !formIsValid.status,
          }"
          role="alert"
          v-if="requestData.status || !formIsValid.status"
        >
          {{ requestData.msg || formIsValid.msg }}
        </div>
      </div>
    </form>
    <h2 class="legend-end mt-3">Estaremos muy felices de que nos puedas acompañar.</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      mail: '',
      flight_number: '',
      accommodation: '',
      food_allergies: '',
      requestData: {
        status: undefined,
        msg: '',
      },
      formIsValid: {
        status: true,
        msg: '',
      },
    };
  },
  methods: {
    setFormIsValid(status, msg) {
      this.formIsValid.status = status;
      this.formIsValid.msg = msg;
    },
    validateForm(fields) {
      console.log('fields', fields);
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fields) {
        if (field.name !== 'food_allergies') {
          if (field.value.toString().trim() === '') {
            this.setFormIsValid(false, 'Los campos del formulario no pueden quedar vacios');
            break;
          }
        }
      }
      if (this.formIsValid.status) {
        this.validateEmail(fields[1].value);
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        this.setFormIsValid(false, 'Ingresa un correo válido');
      }
    },
    async submitFormRSVP() {
      // eslint-disable-next-line no-restricted-globals
      this.$store.commit('set_btnSpinner', event.target);
      this.setFormIsValid(true, '');

      const data = {
        fields: [
          {
            name: 'firstname',
            value: this.name,
          },
          {
            name: 'email',
            value: this.mail,
          },
          {
            name: 'hospedaje',
            value: this.accommodation,
          },
          {
            name: 'flight_number',
            value: this.flight_number,
          },
          {
            name: 'food_allergies',
            value: this.food_allergies,
          },
        ],
        context: {
          pageUri: 'andreaymanuel.mx',
          pageName: 'andreaymanuel',
        },
      };

      this.validateForm(data.fields);

      if (this.formIsValid.status) {
        try {
          const response = await this.$store.dispatch('submitRSVP', data);
          if (response.inlineMessage) {
            this.requestData.status = 'success';
            this.requestData.msg = 'Formulario enviado. Gracias por confirmar tu asistencia.';
          } else {
            this.requestData.status = 'error';
            this.requestData.msg = 'Error al enviar el formulario';
          }
        } catch (error) {
          this.requestData.status = 'error';
          this.requestData.msg = 'Error al enviar el formulario';
        } finally {
          this.$store.commit('set_btnSpinner');
          this.clearInputs();
        }
      } else {
        this.$store.commit('set_btnSpinner');
      }
    },
    clearInputs() {
      this.name = '';
      this.mail = '';
      this.accommodation = '';
      this.flight_number = '';
      this.food_allergies = '';

      setTimeout(() => {
        this.requestData = {
          status: undefined,
          msg: '',
        };
      }, 7000);
    },
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.name === 'QuieresAcompanarnos') {
      setTimeout(() => {
        document.getElementById('quieres-acompanarnos').scrollIntoView();
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>
.quieres-acompanarnos-comp {
  .title {
    margin-top: 31px;
    font-family: "Hammersmith One";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #8bc89d;
  }
  .legend-main {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #8bc89d;
  }
  .legend-sub {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #8bc89d;
  }
  .legend-end {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #8bc89d;
  }

  form {
    input,
    textarea {
      height: 28px;
      border-radius: 5px;
      background: #e2fae9;
      border: 0;
      margin-bottom: 15px;
      width: 70% !important;
    }
    textarea {
      height: 75px;
    }
    input::placeholder,
    textarea::placeholder {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #8bc89d;
      padding: 5px;
      padding-left: 16px;
    }
    button {
      border-radius: 5px;
      background: #e2fae9;
      border: 0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-top: 14px;
      margin-bottom: 30px;
      color: #8bc89d;
      padding: 5px;
      width: 50%;
      height: 48px;
      box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.75);
    }
  }
}

@media (min-width: 1200px) {
  .quieres-acompanarnos-comp {
    .title {
      font-family: "Hammersmith One";
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 88px;
      text-align: center;

      color: #8bc89d;
    }
    .legend-main {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      text-align: center;

      color: #8bc89d;
    }
    .legend-sub {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      text-align: center;

      color: #8bc89d;
    }
    .legend-end {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 37px;
      text-align: center;

      color: #8bc89d;
    }

    form {
      margin-top: 50px;
      input,
      textarea {
        height: 55px;
        border-radius: 5px;
        background: #e2fae9;
        border: 0;
        margin: 10px 5px;
        width: 80%;
      }
      textarea {
        height: 130px;
      }
      input::placeholder,
      textarea::placeholder {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        padding-left: 29px;
        color: #8bc89d;
      }
      button {
        margin-top: 50px;
        border-radius: 5px;
        background: #e2fae9;
        border: 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 49px;
        color: #8bc89d;
        padding: 5px;
        width: 30%;
        height: 135px;
      }
    }
  }
}
</style>
