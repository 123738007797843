import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    portalId: '24272579',
    // formGuid: 'fb3068dc-1272-4af8-94c8-104c4ff4f033', // TESTING
    formGuid: '9b585fbd-f3e0-4525-85a8-ee6f54666905', // PROD
    token: 'pat-na1-64dc3145-a78d-4810-8226-1a09fb730d6b',
    btnAsync: undefined,
  },
  mutations: {
    set_btnSpinner(state, target) {
      if (!state.btnAsync) {
        state.btnAsync = target;
        state.btnAsync.disabled = true;
        state.btnAsync.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      } else {
        state.btnAsync.disabled = false;
        state.btnAsync.innerHTML = 'Confirmar asistencia';
        state.btnAsync = undefined;
      }
    },
  },
  actions: {
    async submitRSVP(context, data) {
      console.log(context, data);
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${context.state.portalId}/${context.state.formGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
            'Authorization': `Bearer ${context.state.token}`,
          },
          body: JSON.stringify(data),
        },
      );
      const responseData = await response.json();
      return responseData;
      // if (responseData.status === 'error') {
      //   const error = new Error(responseData.message || 'Error al enviar formulario');
      //   throw error;
      // }
    },
  },
  modules: {},
});
