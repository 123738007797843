import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Villarino from '../views/Villarino.vue';
import Agenda from '../views/Agenda.vue';
import Informacion from '../views/Informacion.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/villarino',
    name: 'Reservar',
    component: Villarino,
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda,
  },
  {
    path: '/informacion',
    name: 'Informacion',
    component: Informacion,
  },
  {
    path: '/quieres-acompanarnos',
    name: 'QuieresAcompanarnos',
    component: Home,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
