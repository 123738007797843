<template>
  <div class="reservar">
    <TarjetaEventos />
    <QuieresAcompanarnosComp />
  </div>
</template>

<script>
// @ is an alias to /src
import TarjetaEventos from '@/components/Agenda/TarjetaEventos.vue';
import QuieresAcompanarnosComp from '@/components/Home/QuieresAcompanarnosComp.vue';

export default {
  name: 'Home',
  components: {
    TarjetaEventos,
    QuieresAcompanarnosComp,
  },
};
</script>
