<template>
  <div class="home">
    <BannerComp/>
    <MainPicComp/>
    <CuandoDondeComp/>
    <QuieresAcompanarnosComp/>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerComp from '@/components/Home/BannerComp.vue';
import MainPicComp from '@/components/Home/MainPicComp.vue';
import CuandoDondeComp from '@/components/Home/CuandoDondeComp.vue';
import QuieresAcompanarnosComp from '@/components/Home/QuieresAcompanarnosComp.vue';

export default {
  name: 'Home',
  components: {
    BannerComp,
    MainPicComp,
    CuandoDondeComp,
    QuieresAcompanarnosComp,
  },
};
</script>
