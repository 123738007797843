<template>
  <div class="reservar">
    <VillarinoToursComp/>
  </div>
</template>

<script>
// @ is an alias to /src
import VillarinoToursComp from '@/components/Reservar/VillarinoToursComp.vue';

export default {
  name: 'Home',
  components: {
    VillarinoToursComp,
  },
};
</script>
